import { FunctionalComponent, h } from 'preact';
import { Ref, useEffect, useRef, useState } from 'preact/hooks';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import style from './app.css';
import Loader from '../images/Loader';
import PlayCircle from '../images/PlayCircle';
import Volume from '../images/Volume';

const App: FunctionalComponent = () => {
  const videoRef = useRef() as Ref<HTMLVideoElement>;
  const audioRef = useRef() as Ref<HTMLAudioElement>;
  const [videoLoading, setVideoLoading] = useState(true);
  const [audioLoading, setAudioLoading] = useState(true);
  const [canPlay, setCanPlay] = useState(false);
  const [source, setSource] = useState<string>('');
  const currentTime = useRef(0);

  let backward: NodeJS.Timer;

  const handleMediaStart = (): void => {
    if (!canPlay) setCanPlay(true);
  };

  const whichSizeVideo = (): void => {
    currentTime.current = videoRef.current.currentTime;
    if (window.innerWidth >= 1280) setSource(videoRef.current.dataset.lg as string);
    else if (window.innerWidth >= 980) setSource(videoRef.current.dataset.md as string);
    else setSource(videoRef.current.dataset.xs as string);
  };

  const handleAudioLoading = (): void => {
    setAudioLoading(false);
  };

  const handleBackwards = (): void => {
    backward = setInterval(() => {
      videoRef.current.currentTime = videoRef.current.currentTime - 0.05;
      if (videoRef.current.currentTime < 31) {
        clearInterval(backward);
        videoRef.current.play();
      }
    }, 100);
  };

  const handleOnContextMenu = (event: MouseEvent): void => {
    event.preventDefault();
  };

  const handleEvent = (event: Event): void => {
    if (process.env.NODE_ENV !== 'production') console.log('******', `${event.type}\n`);
  };

  useEffect(() => {
    videoRef.current.load();
    videoRef.current.addEventListener('canplaythrough', () => {
      setVideoLoading(false);

      if (canPlay && !videoRef.current.currentTime) {
        videoRef.current.currentTime = currentTime.current;
        videoRef.current.play();
      }
    });
  }, [source, canPlay]);

  useEffect(() => {
    if (canPlay) {
      videoRef.current.play().then();
      audioRef.current.play().then();
    }
  }, [canPlay]);

  useEffect(() => {
    whichSizeVideo();
    audioRef.current = new Audio('/assets/audios/adagio.mp3');
    audioRef.current.load();

    audioRef.current.addEventListener('canplaythrough', handleAudioLoading);
    videoRef.current.addEventListener('ended', handleBackwards);
    window.addEventListener('resize', debounce(whichSizeVideo, 500));

    return (): void => {
      audioRef.current.removeEventListener('canplaythrough', handleAudioLoading);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      window.removeEventListener('resize', debounce(whichSizeVideo, 500));
    };
  }, []);

  useEffect(() => {
    videoRef.current.addEventListener('loadstart', handleEvent);
    videoRef.current.addEventListener('progress', handleEvent);
    videoRef.current.addEventListener('canplay', handleEvent);
    videoRef.current.addEventListener('canplaythrough', handleEvent);
    videoRef.current.addEventListener('ended', handleEvent);
    videoRef.current.addEventListener('pause', handleEvent);
    videoRef.current.addEventListener('play', handleEvent);
    videoRef.current.addEventListener('playing', handleEvent);
    videoRef.current.addEventListener('ratechange', handleEvent);
    videoRef.current.addEventListener('seeking', handleEvent);
    videoRef.current.addEventListener('stalled', handleEvent);
  });

  return (
    <main className={style.main}>
      <video
        className={clsx(style.video, { [style.videoLoading]: !canPlay })}
        controls={false}
        data-lg="/assets/videos/EDM1080.mp4"
        data-md="/assets/videos/EDM720.mp4"
        data-xs="/assets/videos/EDM480.mp4"
        muted={true}
        onContextMenu={handleOnContextMenu}
        playsInline={true}
        preload="auto"
        ref={videoRef}
      >
        <source src={source} type="video/mp4" />
      </video>
      {!canPlay && (
        <div>
          {videoLoading || audioLoading ? (
            <div className={style.loading}>
              <Loader />
            </div>
          ) : (
            <div className={style.iconWrapper} onClick={handleMediaStart}>
              <PlayCircle />
            </div>
          )}
          <p className={style.sentence}>
            <Volume /> Controlla il volume del dispositivo
          </p>
        </div>
      )}
    </main>
  );
};

export default App;
